import React from "react"
import Helmet from 'react-helmet';
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { site, markdownRemark } = data // data.markdownRemark holds your post data
  const { siteMetadata } = site
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title} — {site.siteMetadata.title}</title>
        <meta name="description" content={frontmatter.metaDescription} />
      </Helmet>
      <div className="blog-post-container">
        <article className="single-post">
          {!frontmatter.thumbnail && (
            <div className="post-thumbnail" style={{ backgroundImage: `url('/assets/images/world-map.svg')` }}>
              <div className="post-details">
                <h1 className="h2">{frontmatter.title}</h1>
                <div className="blog-meta">
                  <p className="blog-category">{frontmatter.category}</p>
                </div>
              </div>
            </div>
          )}
          {!!frontmatter.thumbnail && (
            <div className="post-thumbnail" style={{backgroundImage: `url(${frontmatter.thumbnail})`}}>
              <div className="post-details">
                <h1 className="h2">{frontmatter.title}</h1>
                <div className="blog-meta">
                  <p className="blog-category">{frontmatter.category}</p>
                </div>
              </div>
            </div>
          )}
          <div
            className="blog-post-content-grid">
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <div className="portfolio-details">
              <table>
                <thead>
                  <tr>
                    <th colspan="2">Project Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Delivery Date</td>
                    <td><time>{frontmatter.date}</time></td>
                  </tr>
                  <tr>
                    <td>Client</td>
                    <td>{frontmatter.client}</td>
                  </tr>
                  <tr>
                    <td>Task</td>
                    <td>{frontmatter.task}</td>
                  </tr>
                  <tr>
                    <td>Stack</td>
                    <td>{frontmatter.stack}</td>
                  </tr>
                </tbody>
              </table>
            <a target="_blank" className="btn" href={frontmatter.external}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="fa-primary" d="M288 32c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32V192c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3L246.6 310.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L402.7 64H320c-17.7 0-32-14.3-32-32z"></path><path className="fa-secondary" d="M0 112C0 67.8 35.8 32 80 32H192c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16H400c8.8 0 16-7.2 16-16V320c0-17.7 14.3-32 32-32s32 14.3 32 32V432c0 44.2-35.8 80-80 80H80c-44.2 0-80-35.8-80-80V112z"></path></svg>
              Live Project
            </a>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        metaDescription
        category
        client
        task
        stack
        external
      }
    }
  }
`
